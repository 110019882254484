/*
Theme Name: Rumble - Car Rental Booking HTML Template.
Author: codezionsoftwares
Author URL: https://themeforest.net/user/codezionsoftwares/portfolio
Version: 1.0.0
*/
@media (max-width: 1200px) {
	/*navigation*/
	.navigation .main-navigation .main-menu>nav>ul>.menu-item>a {
 padding: 0 10px;
}
 .mb-lg-20 {
 margin-bottom: 20px;
}
 .mb-lg-30 {
 margin-bottom: 30px;
}
 .mb-lg-40 {
 margin-bottom: 40px;
}
 .mb-lg-80 {
 margin-bottom: 80px;
}
}
@media (max-width: 992px) {
	/*Navigation*/
	.header .navigation-wrapper nav>.main-navigation .hamburger-menu {
 display: flex;
}
.header .navigation-wrapper nav>.main-navigation .main-menu>ul>.menu-item:last-child{
	margin-right: 10px;
}
 .header .navigation-wrapper nav>.main-navigation .main-menu {
 display: block;
 position: fixed;
 left: -320px;
 width: 320px;
 height: 100vh;
 top: 0;
 background: #ff0000;
 transition: 0.5s all;
 overflow: auto;
 z-index: 100;
}
 .header .navigation-wrapper nav>.main-navigation .main-menu.active {
 left: 0;
}
 .header .navigation-wrapper nav>.main-navigation .main-menu>ul {
 display: block;
}
 .header .navigation-wrapper nav>.main-navigation .main-menu>ul>.search {
 display: none;
}
 .header .navigation-wrapper nav>.main-navigation .main-menu>ul>.menu-item {
 padding: 0;
 margin: 10px;
}
 .header .navigation-wrapper nav>.main-navigation .main-menu>ul>.menu-item>a {
 padding: 10px 15px;
 display: block;
 background: #fff;
 border: none;
 border-radius: 0;
}
 .header .navigation-wrapper nav>.main-navigation .main-menu>ul>.menu-item.active>a {
 color: #263077;
 font-weight: 500;
}
 .menu-item-has-children>a>.arrow:after, .menu-item-has-megamenu>a>.arrow:after {
 position: absolute;
 right: 15px;
}
 .menu-item-has-children .submenu .menu-item-has-children>.submenu,  .menu-item-has-megamenu:hover>.megamenu,  .menu-item-has-megamenu .megamenu,  .menu-item-has-children .submenu {
 opacity: 1;
 visibility: visible;
 left: 0;
 width: 100%;
 position: relative;
 display: none;
 padding: 0;
 transform: none;
}
 .mb-md-20 {
 margin-bottom: 20px;
}
 .mb-md-30 {
 margin-bottom: 30px;
}
 .mb-md-40 {
 margin-bottom: 40px;
}
 .mb-md-80 {
 margin-bottom: 80px;
}
.about-left-side,
.section-header .section-heading{
	max-width: 100%;
	text-align: center;
}
.section-header.style-left .section-heading h3:after {
    margin: 20px auto 0;
}
.section-header.style-left .section-heading{
	margin: 0;
}
.section-header.style-left{
	text-align: center;
}
.about-left-side .section-header {
    display: block;
	}
}
@media (max-width: 767px) {
	/*topbar*/
	.header .topbar .leftside,  .header .topbar .rightside {
 display: flex;
 justify-content: center;
}
 .header .topbar .rightside ul>li.book-appointment>a,  .header .topbar .rightside ul>li.login {
 padding: 12px 30px;
}
	/*General*/
	.section-header {
 display: block;
}
 .section-header .section-btn {
 display: none;
}
	/*blog*/
	.blog-details .post-details-tags-social .tags-box {
 justify-content: center;
 margin-bottom: 10px;
}
 .blog-details .post-details-tags-social .social-media-box ul {
 justify-content: center;
}
 .blog-details .post-author {
 display: block;
}
 .blog-details .post-author .author-caption {
 flex: 0 0 calc(100% - 0px);
 max-width: calc(100% - 0px);
}
 .coming-soon .coming-soon-text h1 {
 font-size: 40px;
}
 .coming-soon .coming-soon-text .counter .counter-box .inner-box {
 font-size: 28px;
 margin-top: 10px;
}
 .coming-soon .coming-soon-text .counter .counter-box {
 margin: 0 5px;
}
 .mb-sm-20 {
 margin-bottom: 20px;
}
 .mb-sm-30 {
 margin-bottom: 30px;
}
 .mb-sm-40 {
 margin-bottom: 40px;
}
 .mb-sm-80 {
 margin-bottom: 80px;
}
.book-appointment{
	margin-bottom: 10px;
}
}
@media (max-width: 576px) {
	/*topbar*/
	.header .topbar .rightside ul>li.book-appointment>a,  .header .topbar .rightside ul>li.login {
 padding: 8px 10px;
}
	/*Navigation*/
	.header .navigation-wrapper nav>.main-navigation .main-menu {
 left: -260px;
 width: 260px;
}
	/*our services*/
	.service-box .service-wrapper {
 display: block;
}
 .service-box .service-wrapper .service-img,  .service-box .service-wrapper .service-text {
 flex: 0 0 calc(100% - 0px);
 max-width: calc(100% - 0px);
}
 .coming-soon .coming-soon-text .counter {
 flex-wrap: wrap;
}
 .coming-soon .coming-soon-text .counter .counter-box {
 margin: 0 5px 10px;
 width: 100px;
}
 .coming-soon .coming-soon-contact ul {
 justify-content: center;
}
 .coming-soon .coming-soon-contact ul li {
 width: auto;
 padding: 0 15px;
}
 .mb-xs-20 {
 margin-bottom: 20px;
}
 .mb-xs-30 {
 margin-bottom: 30px;
}
 .mb-xs-40 {
 margin-bottom: 40px;
}
 .mb-xs-80 {
 margin-bottom: 80px;
}
 #page404 .txt {
 margin: 0 0 0 -190px;
}
.tabs .nav-tabs .nav-item{
	width: calc(50% - 10px);
}
}
@media (max-width:450px) {
 blockquote {
 padding: 15px;
}
 blockquote p {
 font-size: 14px;
}
 .blog-details .post .post-wrapper .blog-meta .post-meta-box .post-meta .post-date {
 position: absolute;
 top: 20px;
 right: 20px;
 margin-top: 0;
}
 .blog-details .post-details-tags-social span,  .blog-details .post-details-tags-social .social-media-box ul li .social-number,  .blog-details .post-author .author-caption .authorpost {
 display: none;
}
 .blog-details .post-details-tags-social .tags {
 margin-left: 0;
}
 .comment-box .children {
 padding-left: 0;
}
	/*coming soon*/
	.coming-soon .main .counter .counter-box {
 margin: 0 10px;
}
 .coming-soon .main .counter .counter-box .inner-box {
 width: 50px;
 height: 50px;
}
 .about-us .about-left-side ul {
 columns: 1;
}
 .about-us .about-right-side .about-img:before,  .about-us .about-right-side .about-img:after {
 height: calc(50% - 20px
);
}
 .about-us .about-right-side .about-img img {
 padding: 20px;
}
.tabs .nav-tabs .nav-item{
	width: 100%;
	margin-right: 0;
}
}
